<template>
  <div id="headwrapper">
    <div id="header8" :class="{widest : widetoggle}">
      <div id="logo">
        <router-link to="/" v-if="account && account.logo_url" >
          <img style="border-radius: 1px;" :src="'https://corl8-files.s3.amazonaws.com/' + account.logo_url" :title="account.account_name" alt="Home">
        </router-link>
        <router-link to="/" v-else-if="account && account.account_name">
          <div style="font-size:18px;">{{ account.account_name | truncate(15, '...') }}</div>
        </router-link>
        <router-link to="/" v-else>
          <img :src="require('@/assets/foclarity-logo-black-sm.png')" alt="Foclarity" />
        </router-link>
      </div>
      <div id="regularnav">
        <div class="navlinks">
          <ul>
            <router-link tag="li" v-if="isLoggedIn() && (account && (account.user_id === user.user_id) && account.consulting && user.consultant)" to="/clients">My Clients</router-link>
            <router-link tag="li" v-if="isLoggedIn()" to="/profile">My Profile</router-link>
            <router-link tag="li" v-if="isLoggedIn()" :to="{name: 'accounts', query: {r: '/dashboards'}}">Switch Accounts</router-link>
            <li v-if="isLoggedIn()" @click.prevent="feedback = true;">Help</li>
            <router-link class="implink" tag="li" v-if="!isLoggedIn()" to="/who-we-serve">Services</router-link>
            <router-link class="implink" tag="li" v-if="!isLoggedIn()" to="/incubators">Partners</router-link>
            <router-link tag="li" to="/pricing">Pricing</router-link>
            <router-link tag="li" to="/who-we-are">About Us</router-link>
            <router-link tag="li" to="/contact">Contact</router-link>
            <li v-if="isLoggedIn()" @click="handleLogout()">Logout</li>
            <router-link tag="li" to="/login" v-if="!isLoggedIn()">Login</router-link>
            <router-link tag="li" :to="{ name: 'register', params: { r: 'r8jw934uHJnr' }}" v-if="!isLoggedIn()">Register</router-link>
          </ul>
        </div>
      </div>
      <div id="mobilenav" class="menuicon" @click="toggle = !toggle">
        <div class="bar1" :class="{ change: toggle }"></div>
        <div class="bar2" :class="{ change: toggle }"></div>
        <div class="bar3" :class="{ change: toggle }"></div>
      </div>
      <transition name="dropme">
        <div id="nav" :class="{ on: toggle }" v-if="toggle" style="box-shadow: #004466 0px 4px 1px;">
          <ul>
            <template v-if="!isLoggedIn()">
              <router-link tag="li" to="/">Home</router-link>
              <router-link tag="li" to="/who-we-serve">Services</router-link>
              <router-link tag="li" to="/incubators">Partners</router-link>
              <router-link tag="li" to="/pricing">Pricing</router-link>
              <router-link tag="li" to="/who-we-are">About Us</router-link>
              <router-link tag="li" to="/contact" style="border-bottom:2px solid #004466;" >Contact</router-link>
              <router-link tag="li" to="/login">Login</router-link>
              <router-link tag="li" :to="{ name: 'register', params: { r: 'r8jw934uHJnr' }}">Register</router-link>
            </template>
            <template v-if="isLoggedIn()">
              <router-link tag="li" to="/dashboards">Boards</router-link>
              <router-link tag="li" to="/dashboard" v-show="false">
                AI Assistant
                <img :src="require('@/assets/ic_sparkle.png')" width="20" height="20" alt="AI Chat" title="AI Chat" style="cursor:pointer;margin-left:4px;" />
              </router-link>
              <router-link tag="li" to="/mission">Mission</router-link>
              <router-link tag="li" to="/team">Team</router-link>
              <router-link tag="li" to="/profile">Profile</router-link>
              <li @click="showVideo = true" v-if="isLoggedIn()">Demo Video</li>
            </template>
            <template v-if="isLoggedIn() && (account.user_id === user.user_id)">
              <li style="height:2px;background:#004466;"></li>
              <router-link tag="li" v-if="isLoggedIn() && (account && (account.user_id === user.user_id))" :to="{name: 'scorecards', params: {account_id: account.account_id}, query: {r: 'dashboards'}}">New Board</router-link>
              <router-link tag="li" :to="{name: 'account', params: {account_id: account.account_id}, query: {r: '/dashboards'}}">Account</router-link>
            </template>
            <router-link tag="li" v-if="isLoggedIn() && (account && (account.user_id === user.user_id) && account.consulting == true && user.consultant)" to="/clients">Clients</router-link>
            <template v-if="isLoggedIn()">
              <router-link tag="li" to="/accounts" style="border-top:1px solid #004466;">Switch Account</router-link>
              <li @click.prevent="feedback = true;">Help</li>
              <li @click.prevent="handleLogout()" style="border-bottom:2px solid #004466;">Logout</li>
            </template>
          </ul>
        </div>
      </transition>
      <!-- use the modal component, pass in the prop -->
      <feedback v-if="feedback" @close="feedback = false"></feedback>


      <!-- use the modal component, pass in the prop -->
      <modal v-if="showVideo" @close="showVideo = false">
        <template v-slot:header>
          <h3>Foclarity Demo Video</h3>
        </template>
        <template v-slot:body>
          <iframe style="margin-top:10px;width: 100%; aspect-ratio: 16/9;" src="https://www.youtube.com/embed/Wi9PWCJ_2U0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>        
        </template>
        <template v-slot:footer>
          <p>Foclarity helps your team get focused.</p>
        </template>
      </modal>                

    </div>
  </div>
</template>

<script>
import { isLoggedIn, login, logout } from "../../utils/auth";
import { accountService, userService } from '../../_services';
import feedback from "@/components/includes/SendFeedback"
import modal from "@/components/includes/InfoModal"

export default {
  name: "app-nav",
  data() {
    return {
      toggle: false,
      user: "",
      account: "",
      feedback: false,
      fixytoggle: false,
      widetoggle: false,
      search: "",
      showVideo: false
    };
  },
  created () {
    if (this.isLoggedIn()) {
      this.widetoggle = false ;
      this.account = accountService.getActiveAccount()
      userService.getMe().then(data => {
        this.user = data
      });
    }
    // window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    // window.removeEventListener('scroll', this.handleScroll);
  },
  components: {
    feedback,
    modal
  },
  methods: {
    handleLogin() {
      login();
    },
    handleLogout() {
      logout();
    },
    construction() {
      alert("This feature is under construction. Please check back soon.")
      this.search = ""
    },
    handleScroll () {
      // if (screen.width > 440) {
      //   if (window.scrollY > 40){
      //     this.fixytoggle = true
      //   } else {
      //     this.fixytoggle = false
      //   }
      // } else {
      //   if (window.scrollY > 0){
      //     this.fixytoggle = true
      //   } else {
      //     this.fixytoggle = false
      //   }
      // }
    },
    isLoggedIn() {
      return isLoggedIn();
    }    
  }
};
</script>

<style scoped lang="scss">
@import "../../scss/colors";
#headwrapper {
  height:80px;
}
#header8 {
  padding: 10px 10px 2px 10px;
  position: relative;
  min-height: 80px;
  // max-width: 1170px;
  margin: 0 auto;
  // background-color: #ddeeff;
  background: #edf6ff;
  background: linear-gradient(180deg, #ffffff 0%, #edf6ff 80%);
  // border-radius: 40px;
  // border: 1px solid #004466;
  border-bottom:1px solid #004466;
  text-align: left;
  & > #logo {
    display: inline-block;
    font-size: 2em;
    // line-height: 1.5em;
    padding: 8px 0 4px 32px ;
    & > a {
      text-decoration: none;
      border-bottom: 0;
      color: #000;
      & > img {
        height: 30px;
      }
    }
    @media only screen and (max-width: 440px) {
      padding-left: 8px;
    }
    @media only screen and (min-width: 1230px) {
      padding-left: 15px;
    }
  }
  & a {
    font-weight: bold;
    color: $color-main;
    text-decoration: none;
    margin: 0;
    &.router-link-exact-active {
      color: darken($color-accent, 5%);
      border-bottom: 4px dotted $color-accent;
    }
  }
  & > #mobilenav {
    float: right;
    padding: 12px 20px 0 0;
    margin: 0 0 0 0;
  }
  & > #nav {
    z-index: 2000;
    position: absolute;
    top: 53px;
    right: 10px;
    margin-top: 10px;
    width: 300px;
    display: none;
    border-top: 4px solid #004466;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    @media only screen and (max-width: 760px) {
      position: relative;
      top: auto;
      right: auto;
      width: 100%;
      box-shadow: none;
    }
    & > ul {
      width: auto;
      padding: 0;
      margin: 0;
      & > li {
        display: block;
        text-align: left;
        text-transform: capitalize;
        cursor: pointer;
        margin: 0;
        padding: 0 10px;
        font-size: 16px;
        font-weight: 400;
        line-height: 2.5em;
        background: #fff;
        border-bottom: 1px solid #eee;
        color:#004466;
        //border-left: 4px solid #ddd ;
        //border-right: 4px solid #ddd ;
        &.router-link-exact-active {
          color: #333;
          //border-left: 4px solid $color-gray ;
          //border-right: 4px solid $color-gray ;
        }
        &:hover {
          background: #eee;
          //border-left: 4px solid $color-gray ;
          //border-right: 4px solid $color-accent ;
          //color: #fff ;
        }
      }
    }
    &.on {
      display: block;
    }
  }
  &.fixy {
    position: fixed;
    top:0;
    max-width: 100%;
    width: 100%;
    background: #efefef;
    z-index: 1000;
  }
  &.widest {
    max-width: 100% ;
  }
}
.dropme-enter-active,
.dropme-leave-active {
  transition: opacity 0.1s;
}

.dropme-enter, .dropme-leave-to /* .fade-leave-active below version 2.1.8 */
 {
  opacity: 0;
}

.btnHome {
  float: right;
  display: block;
  width:40px;
  text-align: center;
  margin: 0 0 0 20px;
  padding: 0 2px;
  color: #666;
  &.router-link-exact-active {
    display: none;
  }
}

#regularnav {
  @media only screen and (max-width: 760px) {
    display: none;
  }
  float: right;
  padding: 10px 20px 0 0;
  margin: 0 0 0 0;

  .navlinks {
    & > ul {
      width: auto;
      padding: 0;
      margin: 0;
      & > li {
        display: block;
        float:left;
        display: block;
        text-align: left;
        text-transform: capitalize;
        cursor: pointer;
        margin: 0 10px;
        padding: 0 2px;
        font-size: 16px;
        font-weight: 400;
        line-height: 3.5em;
        color: #004466;
        // color:#666;
        &.implink {
          // color: #004466;
          color: #004466;
        }
        &.router-link-exact-active {
          color: #004466;
          border-bottom:4px solid #004466;
        }
        &:hover {
          color: #004466;
        }
      }
    }    
  }
}
.menuicon {
  display: inline-block;
  cursor: pointer;
  @media only screen and (min-width: 761px) {
    display: none;
  }

  & > .bar1,
  .bar2,
  .bar3 {
    width: 35px;
    height: 4px;
    background-color: #676767;
    margin: 6px 0;
    transition: 0.4s;
  }

  & > .bar1 {
    &.change {
      -webkit-transform: rotate(-45deg) translate(-8px, 6px);
      transform: rotate(-45deg) translate(-8px, 6px);
      background-color: #004466;
    }
  }

  & .bar2 {
    &.change {
      opacity: 0;
    }
  }

  & .bar3 {
    &.change {
      -webkit-transform: rotate(45deg) translate(-8px, -6px);
      transform: rotate(45deg) translate(-8px, -6px);
      background-color: #004466;
    }
  }
}
</style>
