<template>
  <div class="content bg8">
    <div class="middled">
      <div class="register" style="margin:auto;">
        <h1 style="margin:20px 0;">
          Set up your account
        </h1>
        <form @submit.prevent="handleSubmit">
            <div class="form-grp">
                <label for="first_name">First Name</label>
                <input type="text" v-model="first_name" name="first_name" autocomplete="given-name" />
            </div>
            <div class="form-grp">
                <label for="last_name">Last Name</label>
                <input type="text" v-model="last_name" name="last_name" autocomplete="family-name" />
            </div>
            <div class="form-grp">
                <label for="email">Email</label>
                <input type="email" v-model="email" name="email" class="form-control" :class="{ 'is-invalid': submitted && !email }" autocomplete="email" />
                <div v-show="submitted && !email" class="invalid-feedback">Email is required</div>
            </div>
            <div class="form-grp">
                <label htmlFor="password">Password</label>
                <input type="password" v-model="password" name="password" class="form-control" :class="{ 'is-invalid': submitted && !password }" autocomplete="new-password" />
                <div v-show="submitted && !password" class="invalid-feedback">Password is required</div>
            </div>
            <div class="form-grp">
              <input type="checkbox" id="terms" name="terms" v-model="accept_terms">
              I Agree to the <a href="/terms-and-conditions" target="_blank">Terms and Conditions</a>
            </div>
            <div class="form-grp">
                <button id="register-me" class="btn btn-primary wide" :disabled="loading || (accept_terms == false)">Register</button>
                <img v-show="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
            </div>
            <div v-if="error" class="alert alert-danger">{{error}}</div>
            <p>
              Already have an account?
              <router-link to="/login">Login</router-link>
            </p>
        </form>
      </div>
      <div style="padding:0 20px;background:#f0f8ff;text-align:center;border:1px solid #ccc;">
        <h2>Not ready to sign up?</h2>
        <p>
          Schedule a call and see how Foclarity works.
        </p>
        <p>
            <a href="/contact">
                <button class="wide"> Schedule a Call </button>
            </a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { router } from '../_helpers';
import { userService } from '../_services';
import { isLoggedIn } from "../utils/auth";

export default {
  title: 'Register | Foclarity',
    data () {
        return {
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            accept_terms: false,
            submitted: false,
            loading: false,
            returnUrl: '',
            error: ''
        }
    },
    props: ['r'],    
    created () {
      // if logged in, send them to contact
      if (isLoggedIn()) {
        this.$router.push("contact");
        return
      }

      // else reset login status
      userService.logout();

      // if they didn't get here from a good link send them home
      if (this.r != 'r8jw934uHJnr') {
        this.$router.push({ name: 'terms-and-conditions', query: {r: 'd' }});
        return
      }

    },
    methods: {
      isLoggedIn() {
        return isLoggedIn();
      },
      // eslint-disable-next-line no-unused-vars
      handleSubmit (e) {
          this.submitted = true;
          const { first_name, last_name, email, password } = this;

          // stop here if form is invalid
          if (!(email && password)) {
            this.error = "Must have an email and password"
            return;
          }

          if (!this.accept_terms) {
            this.error = "Must accept Terms and Conditions to register"
            return;
          }

          this.loading = true;

          userService.checkEmail(email)
            .then(response => {
              return response ;
            })
            .then(res => {
              if (res != "") {
                this.loading = false ;
                this.error = "You're already registered, please login."
                return ;
              }
              if (window.location.host !== 'www.foclarity.com') {
                this.loading = false ;
                this.error = "Hmmm... sorry no fishy."
                return ;
              }
              userService.register(first_name, last_name, email, password, this.accept_terms)
                  .then(
                      // eslint-disable-next-line no-unused-vars
                      user => {
                          userService.login(email, password)
                        .then(
                          res => {
                            if (res != "") {
                              router.push("/accounts")
                            }
                          }
                        )
                      },
                      error => {
                          this.error = error;
                          this.loading = false;
                      }
                  );
            })
      }
    }
};
</script>

<style lang="scss" scoped>
</style>
