<template>
  <div class="content bg8">
    <div class="middled">
      <div class="basehero">
        <h1>
          Digital Marketing Programs
        </h1>
        <h2 class="section">
          Drive growth with <router-link to="pricing" style="color:#BF00FF;">customized marketing services</router-link> and a team dedicated to your goals.
        </h2>
        <p>
          Define and achieve your top marketing priorities with focus, clarity, and agility.
          <ul>
            <li>
              Customizable AI-enhanced frameworks and strategies to get started quickly
            </li>
            <li>
              AI-powered learning and collaboration tools to build team capabilities
            </li>
            <li>
              Powerful cloud software enables remote, hybrid, and in-person collaboration
            </li>
            <li>
              Dedicated marketing associates working to achieve your goals
            </li>
            <li>
              Expert support and consultations help you succeed
            </li>
          </ul>
        </p>
        <p>
          <router-link to="pricing">
            <button style="margin:10px 4px ;padding:10px 20px;background:#fff;border:1px solid #BF00FF;color:#BF00FF;">Compare Packages</button>
          </router-link>
          <a href="https://calendly.com/foclarity/get-started-free" target="_blank" rel="nofollow">
            <button style="margin:10px 4px ;padding:10px 20px;background:#fff;border:1px solid #BF00FF;color:#BF00FF;">Schedule a Demo</button>
          </a>
        </p>
        <h2 class="section">
          <router-link to="pricing" style="color:#BF00FF;">Marketing Services</router-link> include:
        </h2>
        <p>
          <ul>
            <li>
              Digital Marketing
            </li>
            <li>
              Social Media Marketing
            </li>
            <li>
              Content Marketing
            </li>
            <li>
              Email Marketing
            </li>
            <li>
              Search Engine Optimization
            </li>
            <li>
              Pay-Per-Click Advertising
            </li>
            <li>
              Influencer Marketing
            </li>
            <li>
              and more... 
            </li>
          </ul>
        </p>
        <p>
          Choose the Starter, Premium, or VIP Marketing Package to suit your needs and budget.
        </p>
        <p style="padding:20px 0;">
          <a href="https://calendly.com/foclarity/get-started-free" target="_blank" rel="nofollow">
            <button class="wide" style="background: #BF00FF;"> Get Started Free </button>
          </a>
        </p>

        <div class="panel max fullbluelt">
          <div class="row middled">
            <div class="column" style="justify-content:center;margin:auto;">
              <div style="padding:0px 20px;">
                <div class="marketline">
                  Founders ❤️ Foclarity
                </div>
                <h3 style="line-height:1.5em;">
                  &ldquo;Before Foclarity, we were swimming in an endless sea of great ideas and possibilities, 
                  but we had no anchor to tether them to. 
                  Foclarity worked with us to clarify our offerings, 
                  focus on strategic steps forward and ensure that our vision was aligned—both as an executive team and brand. 
                  The platform and people behind it have played a pivotal role in Vault's evolution. 
                  The team is experienced, insightful and genuinely dedicated to our success. 
                  Thank you for helping us find the focus and clarity we needed to succeed!&rdquo;
                </h3>
                <p>
                  Allison Beck<br>
                  Co-founder | Vault
                </p>
              </div>
            </div>
          </div>
        </div>

        <h2 class="section" v-show="false">Benefits of using Foclarity with your team:</h2>
        <ul class="bullets bene" v-show="false">
          <li><strong>Boost Team Performance:</strong> Elevate your team's productivity to consistently achieve and surpass goals.</li>
          <li><strong>Drive Growth and Development:</strong> Foster not just business growth, but also the professional advancement of your team.</li>
          <li><strong>Enhance Communication and Collaboration:</strong> Streamline team interaction for efficiency and effectiveness.</li>
          <li><strong>Cultivate Innovation:</strong> Empower your team to think creatively and solve problems with cutting-edge solutions.</li>
          <li><strong>Build Adaptability:</strong> Equip your team to thrive amidst changes and bounce back stronger from challenges.</li>
          <li><strong>Improve Employee Satisfaction:</strong> Create a work environment where team members feel engaged and valued.</li>
          <li><strong>Align with Vision:</strong> Ensure every team effort contributes directly to your company's overarching goals.</li>
          <li><strong>Uphold Cultural and Ethical Integrity:</strong> Maintain a positive, inclusive, and ethical workplace.</li>
          <li><strong>Maximize Operational Efficiency:</strong> Optimize processes to achieve more with less, reducing costs and increasing value.</li>
        </ul>

        <h2 class="section" v-show="false">
          Additional topics and resources help you learn:
        </h2>

        <div id="tbl_challenges" v-show="false">
          <div class="col_challenges">
            <h3>Focus</h3>
            <div class="itm_challenges">
              <strong>Managing Conflicting Priorities</strong><br>
              Balancing the priorities of upper management with team capabilities and realities.
            </div>
            <div class="itm_challenges">
              <strong>Motivating Teams</strong><br>
              Keeping teams motivated, especially during challenging times or when decisions might not be well-received.
            </div>
            <div class="itm_challenges">
              <strong>Facilitating Team Collaboration</strong><br>
              Fostering an environment of open communication within teams to enhance collaboration and productivity.
            </div>
            <div class="itm_challenges">
              <strong>Time Management for Communication</strong><br>
              Finding the time to communicate effectively amidst a variety of responsibilities.
            </div>
            <div class="itm_challenges">
              <strong>Prioritizing Information</strong><br>
              Deciding what information is crucial and should be communicated immediately versus what can wait.
            </div>
            <div class="itm_challenges">
              <strong>Distraction Management</strong><br>
              Ensuring that both leaders and their team members are not overwhelmed or distracted by less important communications.
            </div>
            <div class="itm_challenges">
              <strong>Emphasizing Key Objectives</strong><br>
              Consistently reinforcing the team's primary goals and objectives in communications to maintain focus.
            </div>
          </div>
          <div class="col_challenges">
            <h3>Clarity</h3>
            <div class="itm_challenges">
              <strong>Bridging Communication Gaps</strong><br>
              Effectively communicating between senior management and team members, often translating and contextualizing messages.
            </div>
            <div class="itm_challenges">
              <strong>Delivering Unpopular Messages</strong><br>
              Conveying difficult or unpopular messages from upper management to teams.
            </div>
            <div class="itm_challenges">
              <strong>Handling Misinformation and Rumors</strong><br>
              Addressing and correcting misinformation and rumors in the workplace.
            </div>
            <div class="itm_challenges">
              <strong>Feedback and Performance Reviews</strong><br>
              Providing constructive feedback and conducting performance reviews in a tactful and clear manner.
            </div>
            <div class="itm_challenges">
              <strong>Consistent Messaging</strong><br>
              Maintaining consistency in messages across different channels and over time.
            </div>
            <div class="itm_challenges">
              <strong>Technical Complexity</strong><br>
              Simplifying complex or technical information for team understanding, especially in diverse fields.
            </div>
            <div class="itm_challenges">
              <strong>Adapting Communication Styles</strong><br>
              Adjusting communication styles to match the preferences and understanding of different team members.
            </div>
          </div>
          <div class="col_challenges">
            <h3>Alignment</h3>
            <div class="itm_challenges">
              <strong>Navigating Organizational Change</strong><br>
              Communicating new processes, structures, or strategies during times of change.
            </div>
            <div class="itm_challenges">
              <strong>Cultural and Language Barriers</strong><br>
              Addressing challenges related to cultural differences and language barriers in diverse workplaces.
            </div>
            <div class="itm_challenges">
              <strong>Ensuring Team Buy-in</strong><br>
              Gaining and maintaining team commitment and agreement with organizational changes or decisions.
            </div>
            <div class="itm_challenges">
              <strong>Aligning Personal and Organizational Goals</strong><br>
              Helping team members understand how their personal goals align with the organization's objectives.
            </div>
            <div class="itm_challenges">
              <strong>Cross-Departmental Communication</strong><br>
              Facilitating effective communication between their team and other departments or units within the organization.
            </div>
          </div>
        </div>

        <div class="row middled" style="margin-bottom:60px;" v-show="false">
          <div class="column">
            <div class="homecard">
              <div class="cardicon">
                <img :src="require('@/assets/ic_assets.png')" alt="Capture ideas" />
              </div>
              <div class="h3">
                Efficiency
              </div>
              <p class="gray">Automate repetitive tasks, access organized information instantly, and focus on critical decision-making</p>
            </div>
          </div>
          <div class="column">
            <div class="homecard">
              <div class="cardicon">
                <img :src="require('@/assets/ic_goals.png')" alt="Score efforts" />
              </div>
              <div class="h3">
                Insights
              </div>
              <p class="gray">AI algorithms extract valuable insights from vast amounts of data, empowering data-driven decision-making</p>
            </div>
          </div>
          <div class="column">
            <div class="homecard">
              <div class="cardicon">
                <img :src="require('@/assets/ic_measure.png')" alt="Create tasks" />
              </div>
              <div class="h3">
                Collaboration
              </div>
              <p class="gray">Foster better teamwork and alignment with real-time updates, shared goals, and centralized access to strategic plans</p>
            </div>
          </div>
          <div class="column">
            <div class="homecard">
              <div class="cardicon">
                <img :src="require('@/assets/ic_polls.png')" alt="Visualize progress" />
              </div>
              <div class="h3">
                Decision Making
              </div>
              <p class="gray">Gain intelligent recommendations and predict outcomes for confident, informed decision-making</p>
            </div>
          </div>
        </div>

        <p v-show="false">
          <router-link :to="{ name: 'register', params: { r: 'r8jw934uHJnr' }}">Sign up</router-link> or <router-link to="contact">contact us</router-link> for a demo.
        </p>

        <div style="text-align: left;" v-show="false">
          <h2>Ready to get started?</h2>
          <p style="padding:20px 0;">
            <a href="https://calendly.com/foclarity/get-started-free" target="_blank" rel="nofollow">
              <button class="wide" style="background: #004466;"> Get Started Free </button>
            </a>
          </p>
        </div>

      </div>
    </div>    
  </div>
</template>

<script>
import { isLoggedIn } from "../utils/auth";

export default {
  title: 'Growth, Marketing & Sales | Foclarity',
  name: "WhoWeServe",
  mounted: function() {
    if (isLoggedIn()) {
      // this.$router.push("dashboards");
    }
  },
  methods: {
    isLoggedIn() {
      return isLoggedIn();
    }
  },
  data () {
      return {
      }
  },
  created () {
  }
};
</script>

<style lang="scss" scoped>

.heroblue {
  background: linear-gradient(110deg, #00b2ee 60%, #007cee 60%);
  height: 320px;
  margin: -20px -20px 20px -20px;
  border-radius: 4px 4px 0 0;
  @media only screen and (max-width: 440px) {
    border-radius: 0px 0px 0 0;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  & h1 {
    text-align: center;
    color: #fff;
    font-size: 45px;
    max-width: 600px;
    @media (max-width: 415px) {
      font-size: 36px;
      padding: 20px;
    }
  }
  & a {
    text-decoration: none;
    color: #fff;
    & .signupbox {
      text-align: center;
      padding: 8px;
      font-size: 24px;
      font-weight: bold;
      background: #ff7f24;
      width: 300px;
      border-radius: 10px;
      border: 1px solid #fff;
      margin: auto;
    }
  }
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  padding: 10px;
  min-width: 200px;
  & .homecard {
    text-align: center;
    padding: 20px;
    border-radius: 4px;
    background: rgba(0, 178, 238, 0.1);
    height: 100%;
    & .cardicon {
      width: 100px;
      height: 100px;
      margin: auto;
      & img {
        width: 80px;
      }
    }
    & .h34 {
      font-size: 23px;
      line-height: 32px;
      color: #004466;
      text-align: left;
      @media (max-width: 400px) {
        font-size: 20px;
      }
    }
  }
}

.homequote {
  text-align: center;
  font-size: 24px;
  line-height: 32px;
  font-style: italic;
  color: #666;
  max-width: 600px;
  margin: 20px auto;
}

.inlinebubble3 {
  color:#fff;
  border:10px solid #f3bc45;
  background:#000 url("~@/assets/simmitri-logo.png") no-repeat 50% 50%;
  background-size:80%;
  padding:40px;
  border-radius:50%;
  width:370px;
  height:370px;
  text-align:center;
  margin: 10px auto;
  @media (max-width: 415px) {
    border:4px solid #f3bc45;
    padding:20px 10px;
    // border-radius:0%;
    width:300px;
    height:300px;
  }
}

#tbl_challenges {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  & .col_challenges {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    padding: 10px;
    min-width: 200px;
    & h3 {
      font-size: 24px;
      line-height: 32px;
      color: #000;
      text-align: left;
      background: #efefef;
      padding: 10px;
      border-bottom: 1px solid #ccc;
      @media (max-width: 400px) {
        font-size: 20px;
      }
    }
    & .itm_challenges {
      font-size: 18px;
      line-height: 28px;
      color: #666;
      text-align: left;
      margin: 10px 0;
      @media (max-width: 400px) {
        font-size: 16px;
      }
    }
  }
}

</style>

