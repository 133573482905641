<template>
  <div id="brow">
    <div class="brownotification" :class="{widest : widetoggle}">
      <div class="newslink" v-if="!isLoggedIn()">
        <router-link to="/who-we-serve">
          Meet R-88
          <img style="width:30px;height:30px;vertical-align: middle;filter: brightness(0) invert(1);" :src="require('@/assets/ic_person.png')" alt="R-88" />
          the AI Marketing Agent &rarr;
          </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { isLoggedIn, login, logout } from "../../utils/auth";
import { accountService, userService } from '../../_services';

export default {
  name: "app-nav",
  data() {
    return {
      toggle: false,
      user: "",
      account: "",
      feedback: false,
      widetoggle: false
    };
  },
  created () {
    if (this.isLoggedIn()) {
      this.widetoggle = false ;
      this.account = accountService.getActiveAccount()
      userService.getMe().then(data => {
        this.user = data
      });
    }
  },
  components: {
  },
  methods: {
    handleLogin() {
      login();
    },
    handleLogout() {
      logout();
    },
    isLoggedIn() {
      return isLoggedIn();
    }    
  }
};
</script>

<style scoped lang="scss">
@import "../../scss/colors";
#brow {
    @media only screen and (max-width: 360px) {
        display: none;
    }
    height: 40px;
    color:#d6ebff;
    background: #2E5077;
    // background: #d6ebff;

    .brownotification {
        text-align: center;
        margin:auto;

        .newslink {
          margin: 0;
          padding: 0 10px;
          font-size: .8rem;
          font-weight: 700;
          line-height: 2.3rem;
          a {
            color:#fff;
            text-decoration: none;
          }
        }
    }

    .brownav {
        padding: 0 30px 0 3px;
        text-align: right;
        // max-width: 1170px;
        margin:auto;

        .newslink {
          float:left;
          //text-transform: uppercase;
          margin: 0;
          padding: 0 10px;
          font-size: .8rem;
          font-weight: 700;
          line-height: 2.5rem;
          a {
            color:#fff;
            text-decoration: none;
          }
        }
        .navlinks {
            float:right;
            & > ul {
            width: auto;
            padding: 0;
            margin: 0;
                & > li {
                    display: block;
                    float:left;
                    display: block;
                    text-align: left;
                    text-transform: uppercase;
                    cursor: pointer;
                    margin: 0;
                    padding: 0 10px;
                    font-size: .8rem;
                    font-weight: 700;
                    text-transform: uppercase;
                    line-height: 2.5rem;
                }
            }    
        }
    }
    & .widest {
      max-width: 100%;
    }
}
</style>
