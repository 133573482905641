<template>
  <div class="content">
    <div class="middled basehero">    
      <div>
        <h1>
          Accounts
        </h1>
        <p class="tiny">
          <span v-if="(memberships.length > 1) || (accounts.length > 1)" v-show="!loading">Each account has it's own members. No content is shared between accounts.</span><br />
        </p>
        <p class="tiny" v-if="(memberships.length == 0) && (invitations.length == 0)">
          IMPORTANT: If you are a collaborator and you don't see the account listed, ask your colleague to invite you to their account using this email: {{ user.email }}
        </p>
      </div>

      <div v-show="loading" style="text-align:center;">Loading...</div>

      <div v-show="!loading">
        <div v-if="accounts.length > 0">
          <h2>My Accounts</h2>
          <div class="row" v-for="account in accounts" :key="account.account_id">
            <div class="column" v-if="(account.deleted == false) || (showdeleted == true)">
              <a v-on:click="switchAccount(account.account_id)">
                  {{ account.account_name }} <span v-show="account.deleted" style="color:red">-- DELETED --</span>
              </a>
              <span class="tiny">
                (Owner)
              </span>
            </div>
          </div>
        </div>
      </div>

      <div v-if="memberships.length > 0" v-show="!loading">
        <h2>My Teams</h2>
        <p class="tiny">
          IMPORTANT: If you are a collaborator and you don't see the account listed, ask your colleague to invite you to their account using this email: {{ user.email }}
        </p>
        <div class="row" v-for="membership in memberships" :key="membership.account_id" style="background:#eee;border-bottom:#ccc;">
          <div class="column" style="flex-grow:4;" v-if="membership.deleted === false">
            <a v-on:click="switchMembership(membership.account_id)">
                {{ membership.account_name }}
            </a>
            <span class="tiny">(Team Member)</span>          
          </div>
        </div>
      </div>

      <div v-if="invitations.length > 0" v-show="!loading">
        <h2>Invitations</h2>
        <p class="tiny" v-if="memberships.length == 0">
          IMPORTANT: If you are a collaborator and you don't see the account listed, ask your colleague to invite you to their account using this email: {{ user.email }}
        </p>
        <div class="row" style="background:#ccffcc;border-bottom:#ccc;" v-for="invitation in invitations" :key="invitation.invitation_id">
          <div class="column" style="flex-grow:3;">
            {{ invitation.sending_user_name }} wants to collaborate with you on {{ invitation.sending_account_name }}
          </div>
          <div class="column" style="width:100px;">
            <button class="wide" v-on:click="accept(invitation.invitation_id)">Accept</button>
          </div>
        </div>
      </div>
      
      <div v-show="!loading">
        <div v-if="!accounts.length && !memberships.length && !invitations.length">
          <h2>Start with a company name</h2>
          <form @submit.prevent="handleSubmit">
              <div class="form-grp">
                  <label for="name">Name</label>
                  <input type="text" v-model="name" name="name" />
                  <div v-show="submitted && !name" class="invalid-feedback">Name is required</div>
              </div>
              <div class="form-grp">
                  <button class="btn btn-primary wide" :disabled="loading">Continue</button>
                  <img v-show="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
              </div>
              <div v-if="error" class="alert alert-danger">{{error}}</div>
          </form>
        </div>
      </div>

      <div class="action-panel">
        <div class="tiny" style="text-align: right;cursor: pointer;" v-if="showdeleted == false" v-on:click="showdeleted = true">show deleted</div>
        <div class="tiny" style="text-align: right;cursor: pointer;" v-else v-on:click="showdeleted = false">hide deleted</div>
        <button class="wide" v-if="accounts.length || memberships.length || invitations.length" v-on:click="checkConsultant">Add Account</button>
      </div>

      <!-- use the modal component, pass in the prop -->
      <modal v-if="showModal" @close="showModal = false">
        <template v-slot:header>
          <h3>Name your new account</h3>
        </template>
        <template v-slot:body>
          <div>
          <form @submit.prevent="handleSubmit">
              <div class="form-grp">
                  <label for="name">Account Name</label>
                  <input type="text" v-model="name" name="name" />
                  <div v-show="submitted && !name" class="invalid-feedback">Account Name is required</div>
              </div>
              <div class="form-grp">
                  <button class="btn btn-primary wide" :disabled="loading">Submit</button>
                  <img v-show="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
              </div>
              <div v-if="error" class="alert alert-danger">{{error}}</div>
          </form>
          </div>
        </template>
        <template v-slot:footer>
          <p class="tiny">
            Each account will have it's own collaborators.
          </p>
        </template>
      </modal>

    </div>


  </div>
</template>

<script>
import { router } from '../_helpers';
import { isLoggedIn } from "../utils/auth";
import { accountService } from "../_services/account.service"
import modal from "@/components/includes/InfoModal"
import { userService } from '../_services';

export default {
  title: 'Accounts | Foclarity',
  name: "Accounts",
  mounted: function() {
    if (isLoggedIn()) {
      // this.$router.push("dashboards");
    }
  },
  methods: {
    premiumFeature(msg) {
      alert(msg + " is a Premium Feature. Contact us to upgrade.")
    },
    isLoggedIn() {
      return isLoggedIn();
    },
    checkConsultant() {
      if (this.user.consultant) {
        this.showModal = true
      } else {
        alert("Adding Accounts is a Premium feature. Contact us to upgrade.")
      }
    }
    ,
    accept: function (iid) {
      accountService.acceptInvitation(iid).then(() => {
        // window.location.href= `/accounts`
        accountService.readAccounts().then(data => {
          this.accounts = data
          this.loading = false
        });
        accountService.readMemberships().then(data => {
          this.memberships = data
        });
        accountService.getInvitations().then(data => {
          this.invitations = data
        })

      })
    },
    switchAccount: function (account_id) {
      accountService.switchAccount(account_id).then(data => {
        var numd = Math.floor(Math.random() * 1000000000);
        this.$router.push("/dashboards?"+numd+data.account_id)
      })
    },
    switchMembership: function (account_id) {
      accountService.switchMembership(account_id).then(data => {
        var numd = Math.floor(Math.random() * 1000000000);
        this.$router.push("/dashboards?"+numd+data.account_id)
      })
    },
    // eslint-disable-next-line no-unused-vars
    handleSubmit (e) {
        this.submitted = true;
        const { name } = this;

        // stop here if form is invalid
        if (!(name)) {
            return;
        }

        this.loading = true;
        accountService.createAccount(name)
            .then(
                // eslint-disable-next-line no-unused-vars
                account => router.push("dashboards"),
                error => {
                    this.error = error ;
                    this.loading = false ;
                    this.submitted = false ;
                }
            );
    }
  },
  components: {
    modal
  },
  data () {
      return {
        user: {},
        accounts: [],
        invitations: [],
        memberships: [],
        showdeleted: false,
        name: '',
        submitted: false,
        loading: true,
        returnUrl: '',
        error: '',
        showModal: false
      }
  },
  created () {
    userService.getMe().then(data => {
      this.user = data
    })
    accountService.readAccounts().then(data => {
      this.accounts = data
      this.loading = false
    });
    accountService.readMemberships().then(data => {
      this.memberships = data
    });
    accountService.getInvitations().then(data => {
      this.invitations = data
    })
  }
};
</script>

<style lang="scss" scoped>
.welcomeback {
  padding: 40px;
  text-align: center;
  border: 1px solid #ccc;
}

.heroblue {
  background: linear-gradient(110deg, #00b2ee 60%, #007cee 60%);
  height: 320px;
  margin: -20px -20px 20px -20px;
  border-radius: 4px 4px 0 0;
  @media only screen and (max-width: 440px) {
    border-radius: 0px 0px 0 0;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  & h1 {
    text-align: center;
    color: #fff;
    font-size: 45px;
    max-width: 600px;
    @media (max-width: 415px) {
      font-size: 36px;
      padding: 20px;
    }
  }
  & a {
    text-decoration: none;
    color: #fff;
    & .signupbox {
      text-align: center;
      padding: 8px;
      font-size: 24px;
      font-weight: bold;
      background: #ff7f24;
      width: 300px;
      border-radius: 10px;
      border: 1px solid #fff;
      margin: auto;
    }
  }
}

</style>

