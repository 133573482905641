<template>
  <div>
    <div v-show="loading">loading...</div>
    <div v-show="!loading">
      <div v-if="(tasks.length > 0) && (!summary)" style="z-index: 999;">
        <div v-show="showCompleted" style="margin:4px 0;">
          <a @click.prevent="showCompleted = false">
            <span style="padding:4px 8px;background:#eee;border:1px solid #eee;border-bottom: 1px solid #ccc;">to-do</span>
          </a>
          <span style="padding:4px 8px;background:#fff;border:1px solid #ccc;border-bottom:1px solid #fff;">completed</span>
        </div>
        <div v-show="!showCompleted" style="margin:4px 0;">
          <span style="padding:4px 8px;background:#fff;border:1px solid #ccc;border-bottom:1px solid #fff;">to-do</span>
          <a @click.prevent="showCompleted = true">
            <span style="padding:4px 8px;background:#eee;border:1px solid #eee;border-bottom: 1px solid #ccc;">completed</span>
          </a>
        </div>
      </div>
    </div>
    
    <div v-show="!summary && !loading">
      <div v-if="tasks.length > 0">
        <div v-for="(task, index) in tasks" :key="task.task_id">
          <div class="row" style="border:0px solid #ccc;border-top:1px solid #ccc;" v-show="showCompleted === task.completed">
            <div class="column checkbox">
              <div class="round">
                <input type="checkbox" v-model="task.completed" >
                <label @click="taskdone(task.task_id, index, task.completed)" title="Toggle completed"></label>
              </div>
            </div>
            <div class="column description">
              <span v-html="task.task_description" v-linkified />
            </div>
            <div class="column tstar">
              <img v-if="task.prioritized" style="width:20px;height:20px;cursor:pointer;" :src="require('@/assets/ic_star_on.png')" alt="star on" @click="prioritizeTask(task.task_id, index, task.prioritized)" />
              <img v-else style="width:20px;height:20px;cursor:pointer;" :src="require('@/assets/ic_star_off.png')" alt="star off" @click="prioritizeTask(task.task_id, index, task.prioritized)" />
            </div>
          </div>
          <div class="row" style="background:#fff;margin-bottom:10px;margin-left:36px;"  v-show="showCompleted === task.completed">
            <div style="font-size:.8rem;color:#06C;text-transform:capitalize;cursor: pointer;" v-on:click.prevent="assignUser(task.assigned_user_id, task.task_description, task.task_id, task.due_by, task.prioritized, index)">
              <span v-if="task.completed">{{ task.updated_at | moment("MMM DD, YYYY") }} &bull; </span>
              <span v-else :style="{color: overdue(task.due_by, task.completed)}">Due: {{ task.due_by | moment("MMM DD, YYYY") }}</span>
              &#8680; {{ task.assigned_user_name }} {{ task.assigned_by ? "(assigned by " + task.assigned_by + ")" : "" }} &bull; Edit
            </div>
          </div>
        </div> 
      </div>

      <div class="row" v-show="!loading">
        <div class="column" style="flex-grow:4;">
          <input type="text" v-model="task_description" placeholder="Task:" v-on:keyup.enter="maketask">
          <transition name="fade">
            <div v-show="error_message">
                {{ error_message }}
            </div>
          </transition>
          <div v-show="task_description">
            <div v-show="!starttasking" style="cursor:pointer;text-align:right;padding-right:4px;" @click="startnewtask" class="tiny">Options</div>
            <div v-show="starttasking" style="cursor:pointer;text-align:right;padding-right:4px;" @click="endnewtask" class="tiny">Clear</div>
          </div>
        </div>
        <div class="column" style="min-width:100px;padding-top:10px;">
          <button :disabled="task_description.length == 0" class="btn-add" style="padding:10px 20px;white-space: nowrap;" v-on:click="maketask">Add</button>
        </div>
      </div>

      <div style="padding:8px;" v-show="starttasking && !loading">
        <div class="form-grp">
          <label>Assign to</label>
          <select v-model="edit_ass_id">
              <option 
                v-for="member in members" 
                :key="member.member_id" 
                :value="member.member_id"
                :selected="member.member_id == edit_ass_id"
                >
                  {{member.member_first_name}} {{member.member_last_name}}
              </option>
          </select>
        </div>
        <div class="form-grp">
          <label>Due By</label>
          <template>
            <div class='date-picker'>
              <div class='custom-select'>
                <select @change='editValue("month", $event)' v-model='month'>
                  <option
                    v-for='(month, index) in months'
                    :key='month'
                    :value='index'>
                    {{ month }}
                  </option>
                </select>
              </div>
              <div class='custom-select'>
                <select @change='editValue("date", $event)' v-model='date'>
                  <option
                    v-for='i in daysInMonth'
                    :key='i'
                    :value='i'>
                    {{ i }}
                  </option>
                </select>
              </div>
              <div class='custom-select'>
                <select @change='editValue("year", $event)' v-model='year'>
                  <option
                    v-for='i in numberofYears'
                    :key='i'
                    :value='startingYear + (i - 1)'>
                    {{ startingYear + (i - 1) }}
                  </option>
                </select>
              </div>
            </div>
          </template>
          <div style="clear:both;"></div>
        </div>
      </div>              
            

    </div>

    <!-- use the modal component, pass in the prop -->
    <modal v-if="showEditTask" @close="cancelEditTask">
      <template v-slot:header>
        <h3>Edit Task</h3>
      </template>
      <template v-slot:body>
        <div class="form-grp">
          <label>Assign to:</label>
          <select style="width:100%;padding:4px;" v-model="edit_ass_id">
              <option 
                v-for="member in members" 
                :key="member.member_id" 
                :value="member.member_id"
                :selected="member.member_id == edit_ass_id"
                >
                  {{member.member_first_name}} {{member.member_last_name}}
              </option>
          </select>
        </div>
        <div class="form-grp">
            <label>Task Description:</label>
            <textarea v-model="edit_task_description" />
        </div>
        <div class="form-grp">
          <label>Due By:</label>
          <template>
            <div class='date-picker'>
              <div class='custom-select'>
                <select @change='editValue("month", $event)' v-model='month'>
                  <option
                    v-for='(month, index) in months'
                    :key='month'
                    :value='index'>
                    {{ month }}
                  </option>
                </select>
              </div>
              <div class='custom-select'>
                <select @change='editValue("date", $event)' v-model='date'>
                  <option
                    v-for='i in daysInMonth'
                    :key='i'
                    :value='i'>
                    {{ i }}
                  </option>
                </select>
              </div>
              <div class='custom-select'>
                <select @change='editValue("year", $event)' v-model='year'>
                  <option
                    v-for='i in numberofYears'
                    :key='i'
                    :value='startingYear + (i - 1)'>
                    {{ startingYear + (i - 1) }}
                  </option>
                </select>
              </div>
            </div>
          </template>
          <div style="clear:both;"></div>
        </div>
        <div class="form-group">
            <button style="margin-right:auto;padding:10px 20px;" class="btn btn-primary" @click="editTask">Save</button>
            <button style="margin-left:8px;background: #333;" v-on:click="cancelEditTask">Cancel</button>
        </div>
      </template>
      <template v-slot:footer>
        <div v-if="edit_task_msg">{{edit_task_msg}}</div>
        <div v-else>
          Save your changes before closing
          <a href="#" style="float:right;color:#ff0000;font-size: .85em;" @click.prevent="deleteTask(edit_task_id, edit_index)">Delete Task</a>
        </div>
      </template>
    </modal>
    
  </div>
</template>

<script>
import { accountService, taskService } from '../../_services' ;
import modal from "@/components/includes/InfoModal"
import moment from 'moment'

export default {
  props: [
    'scid',
    'cid',
    'mid',
    'summary'
  ],
  data () {
      return {
        showCompleted: false,
        members: [],
        task_description: "",
        tasks: [],
        loading: true,
        error: '',
        open: true,
        showEditTask: false,
        edit_task_id: 0,
        edit_ass_id: 0,
        edit_task_description: "",
        edit_due_by: "",
        edit_prioritized: false,
        edit_index: 0,
        edit_task_msg: "",
        months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        startingYear: 2021,
        numberofYears: 5,
        daysInMonth: 30,
        dateValue: "",
        month: "",
        date: "",
        year: "",
        starttasking: false,
        addtaskbtn: "Options",
        enableOptions: false,
        error_message: ""
      }
  },
  methods: {
    editValue: function (unit, evt) {
      this.dateValue.set(unit, evt.target.value)
      this.daysInMonth = this.dateValue.daysInMonth()
      this.edit_due_by = this.dateValue.format()
      // 2021-05-28T12:53:12.247522-07:00
      this.updateElements()
    },
    updateElements: function () {
      // month.value gives us the <select> 
      this.month = this.dateValue.month()
      this.date = this.dateValue.date()
      this.year = this.dateValue.format('YYYY')
    },
    construction: (d) => {
      alert(d)
    },
    overdue(due_by, completed) {
      if (!completed) {
        var today = new Date();
        var due = new Date(due_by);
        if (due < today) {
          return "#f00"
        } else {
          return "#666"
        }
      } else {
        return "#666"
      }
    },
    maketask: function () {
      if (this.edit_due_by == "") {
        var newDate = new Date()
        // make the newDate 7 days from now
        newDate.setDate(newDate.getDate() + 7)
        var dateValue = moment(newDate)
        this.edit_due_by = dateValue.format()
      }
      taskService.createMeasureTask(this.scid, this.cid, this.mid, this.task_description, this.edit_prioritized, this.edit_ass_id, this.edit_due_by).then(
        data => {
            this.tasks.push(data.task)
            this.task_description = ""
            this.edit_prioritized = false
            this.edit_ass_id = ""
            this.edit_due_by = ""
            this.starttasking = false
            this.enableOptions = false
        },
        error => {
            this.error_message = error
            this.task_description = ""
            this.edit_prioritized = false
            this.edit_ass_id = ""
            this.edit_due_by = ""
            this.starttasking = false
            this.enableOptions = false
            setTimeout(() => this.error_message = "", 3000);
          }
      )
    },
    taskdone: function (task_id, index, completed) {
      taskService.toggleMeasureTask(this.scid, this.cid, this.mid, task_id, completed).then(
        data => {
          if (data.status == "success") {
            this.tasks[index].completed = !completed ;
          } else {
            alert("Ran into an issue trying to update this task. Please try again.")
          }
        }
      )
    },
    prioritizeTask: function (task_id, index, prioritized) {
      taskService.prioritizeMeasureTask(this.scid, this.cid, this.mid, task_id, prioritized).then(
        data => {
          if (data.status == "success") {
            this.tasks[index].prioritized = data.task.prioritized ;
          } else {
            alert("Ran into an issue trying to update this task. Please try again.")
          }
        }
      )
    },
    deleteTask: function (task_id, index) {
      if(confirm("Really want to delete this task?")){
        taskService.deleteMeasureTask(this.scid, this.cid, this.mid, task_id).then(
          data => {
            if (data.status == "success") {
              this.tasks.splice(index, 1) ;
              this.showEditTask = false ;
            } else {
              alert("Ran into an issue trying to delete this task. Please try again.")
            }
          }
        )
      }
    },
    startnewtask: function () {
      var newDate = new Date()
      // make the newDate 7 days from now
      newDate.setDate(newDate.getDate() + 7)
      this.dateValue = moment(newDate)
      this.daysInMonth = this.dateValue.daysInMonth()
      this.edit_due_by = this.dateValue.format()
      this.updateElements()
      this.starttasking = true
    },
    endnewtask: function () {
      this.task_description = ""
      this.edit_ass_id = ""
      this.edit_due_by = ""
      this.starttasking = false
      this.enableOptions = false
    },
    assignUser: function (assigned_user_id, task_description, task_id, due_by, prioritized, index) {
      this.edit_ass_id = assigned_user_id
      this.edit_task_description = task_description
      this.edit_task_id = task_id
      this.edit_due_by = due_by
      this.edit_prioritized = prioritized
      this.edit_index = index
      this.dateValue = moment(due_by)
      this.daysInMonth = this.dateValue.daysInMonth()
      this.updateElements()
      this.showEditTask = true
    },
    cancelEditTask: function () {
      this.edit_task_id = 0
      this.edit_ass_id = 0
      this.edit_task_description = ""
      this.edit_due_by = ""
      this.edit_prioritized = false
      this.edit_index= 0
      this.edit_task_msg = ""
      this.showEditTask = false ;
    },
    editTask: function () {
      taskService.updateMeasureTask(this.scid, this.cid, this.mid, this.edit_task_id, this.edit_task_description, this.edit_ass_id, this.edit_due_by, this.edit_prioritized).then(
        data => {
          if (data.status == "success") {
            this.tasks[this.edit_index].task_description = data.task.task_description ;
            this.tasks[this.edit_index].assigned_user_id = data.task.assigned_user_id ;
            this.tasks[this.edit_index].assigned_user_name = data.task.assigned_user_name ;
            this.tasks[this.edit_index].completed = data.task.completed ;
            this.tasks[this.edit_index].due_by = data.task.due_by ;
            this.tasks[this.edit_index].prioritized = data.task.prioritized ;
            this.edit_task_id = 0
            this.edit_ass_id = 0
            this.edit_task_description = ""
            this.edit_due_by = ""
            this.edit_prioritized = false
            this.edit_index= 0
            this.edit_task_msg = ""
            this.showEditTask = false ;
          } else {
            alert("Ran into an issue trying to update this task. Please try again.")
          }
        },
        () => {
          // console.debug(error)
          alert("Ran into an issue trying to update this task. Please try again.")
        }
      )
    },
    loadTasks: function () {
      taskService.readMeasureTasks(this.scid, this.cid, this.mid).then(
        data => {
          this.tasks = data.tasks
          this.loading = false
        },
        error => {
          //console.debug(error)
          this.error = error
          this.loading = false
        }
      )
    },
    getMembers: function () {
      accountService.readAccountMembers().then(
        data => {
          this.members = data.members
        }
      )
    },
  },
  components: {
    modal
  },
  created () {
    this.loadTasks();
    this.getMembers();
  }
};
</script>

<style>
.task {
  padding: 10px ;
}
.newtask {
  text-align: center ;
  padding: 40px 4px ;
  cursor: pointer ;
}

  /* wrapper-div for including arrow */
  .custom-select {
    background-color: #eee;
    float: left;
    margin-right: 10px;
    position: relative;    
  }

  .custom-select select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none; /* remove default styling */
    background-color: inherit;
    border: none;
    color: #333;
    display: block;
    font-size: 16px;
    height: 32px;
    padding: 5px 30px 5px 10px;
    margin: 0;
    outline: none;
  }

  /* drop arrow */
  .custom-select:after {
    content: '\25bc'; 
    color: #AAA;
    font-size: 12px;
    position: absolute;
    right: 8px;
    top: 10px;
  }

.round {
  position: relative;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 22px;
  left: 0;
  position: absolute;
  top: 0;
  width: 22px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 4px;
  opacity: 0;
  position: absolute;
  top: 6px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: #66bb6a;
  border-color: #66bb6a;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

.column.checkbox {
  flex-grow:0;
  min-width:30px;
}
.column.description {
  flex-grow:3;
  font-size:1rem;
  overflow:hidden;
}
.column.tstar {
  flex-grow:0;
  text-align:right;
  font-size:.8rem;
  min-width:30px;
}

</style>