<template>
  <div class="content bg8">
    <div class="middled">
      <div class="basehero" style="">
        <h1 style="text-align: center;">Select a <span style="color:#BF00FF;">Marketing</span> Program</h1>
        <p style="text-align: center;">Choose an option below</p>
        <div class="row">
          <div class="column">
            <div style="padding:0 10px;background:#f0f8ff;border:4px solid #ccc;flex:1;">
              <h3 style="text-align:center" title="Basic Marketing Services">Starter</h3>
              <ul class="pricing-list">
                <li>Social Media (2 channels)</li>
                <li>Email Creative (1 per month)</li>
                <li>Basic Content Creation</li>
                <li>Basic SEO / website updates</li>
                <li>Quarterly Strategy Session</li>
                <li>Monthly Marketing Meeting</li>
                <li>On-demand Analytics</li>
                <li>AI-enhanced Tasks</li>
                <li>Progress Tracking</li>
                <li>Visual Reporting</li>
              </ul>
              <p style="font-size:2rem;text-align:center">
                $1,500<br>
                <span style="font-size:.8rem;">per month</span>
              </p>
              <p style="text-align:center">
                <a href="https://calendly.com/foclarity/get-started-free" target="_blank" rel="nofollow">
                  <button style="padding:10px 20px;background: #BF00FF;"> Start Free </button>
                </a>
              </p>
            </div>
          </div>
          <div class="column">
            <div style="padding:0 10px;background:#f0f8ff;border:4px solid #BF00FF;flex:1;">
              <h3 style="text-align:center" title="Collaborate with your team">Premium</h3>
              <ul class="pricing-list">
                <li>Social Media (4 channels)</li>
                <li>Email Creative (2 per month)</li>
                <li>Advanced Content Creation</li>
                <li>Advanced SEO / website updates</li>
                <li>Monthly Strategy Session</li>
                <li>Weekly Marketing Meeting</li>
                <li>Monthly Analytics Report</li>
                <li>AI-enhanced Learning</li>
                <li>Advanced Tracking</li>
                <li>Advanced Reporting</li>
              </ul>
              <p style="font-size:2rem;text-align:center">
                $3,000<br>
                <span style="font-size:.8rem;">per month</span>
              </p>
              <p style="text-align:center">
                <a href="https://calendly.com/foclarity/get-started-free" target="_blank" rel="nofollow">
                  <button style="padding:10px 20px;background: #BF00FF;"> Start Free </button>
                </a>
              </p>
            </div>
          </div>
          <div class="column">
            <div style="padding:0 10px;background:#f0f8ff;border:4px solid #ccc;flex:1;">
              <h3 style="text-align:center">VIP</h3>
              <ul class="pricing-list">
                <li>Social Media / Paid Ad Management</li>
                <li>Email Creative (4 per month)</li>
                <li>Advanced Content / Video Creation</li>
                <li>Podcast / Video Editing</li>
                <li>Quarterly Workshops</li>
                <li>Weekly Strategy Meeting</li>
                <li>Advanced Analytics</li>
                <li>AI Agent Programming</li>
                <li>Campaign Tracking</li>
                <li>Personalized Support</li>
              </ul>
              <p style="font-size:2rem;text-align:center">
                $10,000<br>
                <span style="font-size:.8rem;">per month</span>
              </p>
              <p style="text-align:center">
                <a href="https://calendly.com/foclarity/get-started-free" target="_blank" rel="nofollow">
                  <button style="padding:10px 20px;background: #BF00FF;"> Start Free </button>
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div class="column">
          <div style="padding:0 10px;flex:1;">
            <h3 style="border-bottom:1px solid #ccc;text-align:center" title="Collaborate with your team">Additional Services</h3>
            <p style="text-align: center;">For clients who want extra services, we have à la carte options:</p>
            <ul class="pricing-list">
              <li>Strategy Sessions</li>
              <li>Website Design and Hosting</li>
              <li>Advanced Website Content (Blogs, Landing Pages)</li>
              <li>Paid Ad Campaign Management</li>
              <li>Advanced Video Content Creation</li>
              <li>Podcast Management</li>
              <li>Advanced Analytics</li>
              <li>Marketing Automation</li>
              <li>Corporate Brand Identity and Logo Design</li>
              <li>Product Marketing Strategy Sessions</li>
              <li>Marketing Workshops for Your Team</li>
              <li>Customized Marketing Plans</li>
              <li>and more...</li>
            </ul>
          </div>
        </div>

      </div>

      <div class="basehero" style="padding:0 40px;text-align: center;">
        <h2>Not ready to sign up?</h2>
        <p>
            <a href="/contact">
                <button style="width:250px;background: #BF00FF;"> Book a Call </button>
            </a>
        </p>
      </div>

    </div>    
  </div>
</template>

<script>
import { isLoggedIn } from "../utils/auth";

export default {
  title: 'Pricing | Foclarity',
  name: "Pricing",
  mounted: function() {
    if (isLoggedIn()) {
      // this.$router.push("dashboards");
    }
  },
  methods: {
    isLoggedIn() {
      return isLoggedIn();
    },
    checkForMetaMask: function () {
      //Have to check the ethereum binding on the window object to see if it's installed
      const { ethereum } = window;
      var isinstalled = Boolean(ethereum && ethereum.isMetaMask);
      console.log("Metamask is connected: " + isinstalled)

      try {
        // Will open the MetaMask UI
        // You should disable this button while the request is pending!
        ethereum.request({ method: "eth_requestAccounts" });
      } catch (error) {
        console.error(error);
      }

    },
  },
  components: {},
  data () {
      return {
      }
  },
  created () {
    // this.checkForMetaMask() ;
  }
};
</script>

<style lang="scss" scoped>

.heroblue {
  background: linear-gradient(110deg, #00b2ee 60%, #007cee 60%);
  height: 320px;
  margin: -20px -20px 20px -20px;
  border-radius: 4px 4px 0 0;
  @media only screen and (max-width: 440px) {
    border-radius: 0px 0px 0 0;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  & h1 {
    text-align: center;
    color: #fff;
    font-size: 45px;
    max-width: 600px;
    @media (max-width: 415px) {
      font-size: 36px;
      padding: 20px;
    }
  }
  & a {
    text-decoration: none;
    color: #fff;
    & .signupbox {
      text-align: center;
      padding: 8px;
      font-size: 24px;
      font-weight: bold;
      background: #ff7f24;
      width: 300px;
      border-radius: 10px;
      border: 1px solid #fff;
      margin: auto;
    }
  }
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  padding: 10px;
  min-width: 200px;
  & .homecard {
    text-align: center;
    padding: 20px;
    border-radius: 4px;
    background: rgba(0, 178, 238, 0.1);
    height: 100%;
    & .cardicon {
      width: 100px;
      height: 100px;
      margin: auto;
      & img {
        width: 80px;
      }
    }
    & .h3 {
      font-size: 23px;
      line-height: 32px;
      color: #CC0099;
      text-align: left;
      @media (max-width: 400px) {
        font-size: 20px;
      }
    }
  }
}

.homequote {
  text-align: center;
  font-size: 24px;
  line-height: 32px;
  font-style: italic;
  color: #666;
  max-width: 600px;
  margin: 20px auto;
}
.pricing-list {
  list-style-type: none;
  padding: 0;
  & li {
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }
}
</style>

