<template>
  <footer>
    <div>
      <img :src="require('@/assets/foclarity-logo-black-sm.png')" alt="Foclarity" width="200" />
    </div>
    <div class="links">
      <div class="flink"><router-link to="/">Home</router-link></div>
      <div class="flink"><router-link to="/who-we-are">About</router-link></div>
      <div class="flink"><router-link to="/who-we-serve">Services</router-link></div>
      <div class="flink"><router-link to="/incubators">Partners</router-link></div>
    </div>
    <div class="links">
      <div class="flink">
        <a href="https://x.com/Foclarity" target="_blank" rel="noreferrer" >
          <img class="social-icon" src="@/assets/x-logo-black.png" alt="@Foclarity" />
        </a>
      </div>
      <div class="flink">
        <a href="https://discord.gg/ndkD2dmAvS" target="_blank" rel="noreferrer" >
          <img class="social-icon" src="@/assets/ic_discord.svg" alt="Foclarity Discord" />
        </a>
      </div>
      <div class="flink">
        <a href="https://linkedin.com/company/Foclarity" target="_blank" rel="noreferrer" >
          <img class="social-icon" src="@/assets/ic_linkedin_52.png" alt="Foclarity LinkedIN" />
        </a>
      </div>
      <div class="flink">
        <a href="https://www.youtube.com/channel/UCgNpF3KSGVQdTqx-RwBxBqw" target="_blank" rel="noreferrer">
          <img class="social-icon" src="@/assets/yt_logo_mono_light.png" alt="Foclarity YouTube Channel" />
        </a>
      </div>
    </div>

    <div class="links">
      <div class="flink"><router-link to="/privacy">Privacy</router-link></div>
      <div class="flink"><router-link to="/contact">Contact</router-link></div>
    </div>
    <div class="links">
      <div class="flink">Copyright &copy;2025 Foclarity AI - a division of Custany, LLC</div>
    </div>
    <div class="links">
      <div>{{ lastlogin }}</div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      sociallinks: "Facebook Twitter LinkedIn",
      lastlogin: ''
    }
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
footer {
  margin: 0;
  padding: 30px 30px;
  text-align: center;
  @media only screen and (max-width: 600px) {
    text-align: center;
    padding: 30px 0px;
  }
  background: #eee;
  z-index: 10000;
  & .links {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: .75rem;
    justify-content: center ;
    & .flink {
      padding: 10px ;
      display: flex;
      flex-direction: table;
      flex-basis: 100%;
      flex: 0 1 auto ;
    }
    @media only screen and (max-width: 600px) {
      justify-content: center;
    }
  }
}
.social-icon {
  height: 20px ;
}
</style>
