<template>
  <div class="content bg8">
    <div class="middled">
      <div class="basehero">
        <h1>Contact Us</h1>
        <h3 class="section">
          Fill out the form, or
          <a href="https://calendly.com/foclarity/30min" target="_blank">
              schedule a call with us here.
          </a>
        </h3>
      </div>
      <div class="basehero" style="text-align:center;">
          <div>
            <iframe style="min-width:368px;width:100%;height:1200px;" src="https://us7.list-manage.com/contact-form?u=2c36f327fea570fc06cb3650e&form_id=3ab7a669ccdd4a29b007977401c632fa" frameborder="0"></iframe>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import { router } from '../_helpers';
import { userService } from '../_services';

export default {
  title: 'Contact | Foclarity',
  data () {
      return {
          email: '',
          password: '',
          getverifiedlink: false,
          submitted: false,
          loading: false,
          returnUrl: '',
          error: '',
          contactform: false
      }
  },
  created () {
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    handleSubmit (e) {
        this.submitted = true;
        const { email, password } = this;

        // stop here if form is invalid
        if (!(email && password)) {
            return;
        }

        this.loading = true;
        userService.login(email, password)
            .then(
                // eslint-disable-next-line no-unused-vars
                user => router.push(this.returnUrl),
                error => {
                    this.error = error ;
                    if (this.error == "Account not verified") {
                      this.getverifiedlink = true ;
                    }
                    this.loading = false ;
                }
            );
    }
  }
};
</script>

<style lang="scss" scoped>
.login {
  padding: 20px;
  & input {
    background: #eee ;
  }
}
.contactframe {
    width:100%;
    height: 1000px;
}
</style>
